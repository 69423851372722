@import "@ng-select/ng-select/themes/default.theme.css";


body {
  margin: 0;
  padding: 0;
  font-size: 0.780rem !important;
  line-height: 1.5 !important;
  font-family: OpenSans !important;
  font-weight: 500;
  letter-spacing: .2px ;
  overflow-x: hidden;
  height: 100%;
  color: $content_color !important;

  ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .total-padding {
        min-height: 2rem;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: OpenSans;
  letter-spacing: .2px;
  margin-bottom: 0.5rem;
}

h6 {
  font-weight: bold;
}

.mb-3 {
  .form-control {
    font-size: 0.780rem;

    &:read-only {
      background: #f9f9f9;
      border-color: #ced4da;
      box-shadow: none;
    }

    &:disabled {
      background: #f9f9f9;
      border-color: #ced4da;
      box-shadow: none;
    }
  }

  .input-group {
    .input-group-append {
      .btn {
        height: 2rem;
        background: $white;
        line-height: 1px;

        &:hover {
          background: $white;
          color: $bg-action-button;
        }

        &:disabled {
          color: #989fa5;
        }
      }
    }
  }
}

.form-control {
  font-size: 0.780rem !important;

  &:read-only {
    background: #f9f9f9;
    border-color: #ced4da;
    box-shadow: none;
  }

  &:disabled {
    background: #f9f9f9;
    border-color: #ced4da;
    box-shadow: none;
  }
}

.dragDrop {
  border: 1px solid #ccc;
  height: 6rem;
  padding: 5px;
  border-radius: 5px;
  background: white;
  width: 100%;
  white-space: normal;
  overflow: auto;
}

.dragLabel {
  color: gray;
}

.input-group {
  .input-group-append {
    .btn {
      height: 2rem;
    //  background: $white;
      line-height: 1px;

      &:hover {
        background: $white;
        color: $bg-action-button;
      }

      &:disabled {
        color: #989fa5;
      }
    }
  }
}

.ng-select .ng-select-container {
  min-height: 33px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 33px;
}

ui-switch {
  .switch:focus {
    outline: none;
  }
}

.bold-title {
  font-weight: bold;
  //  font-family: OpenSansBold;
  //  letter-spacing: .4px;
}

.ngx-datatable.material {
  box-shadow: none !important;
  border-radius: 0px 0px 10px 10px !important;
  border: 1px solid hsl(210, 14%, 83%) !important;
  // border-top: 1px solid $bg-action-button;

  .datatable-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

    .datatable-header-cell {
      background: $bg-action-button !important;
      color: $white !important;
      padding: .5rem 1rem !important;

      .sort-btn {
        color: white;
        //font-size: 16px; font size change
        font-size: 20px;
      }
    }
  }

  .datatable-body {
    .datatable-row-detail {
      // height: 10rem;
      overflow-x: hidden;
    }

    .datatable-body-cell-label {
      font-size: 0.780rem
    }
  }
}

.month-picker {
  dp-date-picker {
    width: 100%;
    border: 1px solid #ced4da;
    height: 100%;
    border-radius: 4px;

    input {
      font-size: 15px !important;
      height: 2.1rem !important;
      width: 21.5rem !important;
      border: none !important;
    }

    &:last-child {
      box-sizing: border-box;
      font-size: 13px;
      height: 2.1rem;
      width: 100%;
      border: none;
    }
  }

  input:focus {
    border-color: #80bdff;
    transition: all 0.5s;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
  }
}

// Auto populate Styles
.ng-autocomplete {
  width: 15rem;

  ::ng-deep .autocomplete-container {
    height: 2rem;

    input {
      border-radius: 0.25rem;
      height: 2rem;
    }
  }
}

//Modal styles

// ngb-modal-window{
//   margin-top: 50px;
 
// }
//
.modal-dialog{
  margin-bottom: 60px !important;
}

// .modal-backdrop.show {
//   opacity: 0.7 !important;
// }

.modal-header {
  padding: 0.25rem;
  background: $bg-action-button;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;


  .modal-title {
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
    color: $white;
    margin-bottom: 0;
    line-height: 1rem;
    vertical-align: middle;
  }

  .close {
    // padding: 0;
    // margin: 0;
    // color: $white !important;
    // outline: none;
    // font-size: 0.8rem;
    // font-weight: 700;
    // line-height: 1;
    // text-shadow: 0 1px 0 #fff;
    // opacity: .5;
    // filter: brightness(0) invert(1); 
    background-color: transparent;
			color: white;
			border: none;
			font-size: x-large;
			font-weight: bold;
  }

  .btn-close:hover, 
  .btn-close:focus {
    background-color: transparent; 
    border: none; 
    box-shadow: none; 
}
  
}

  .accordian-bg {
    background-color: #e9ecef !important;
    border: none !important;
  }

  .breadcrumb-item.active {
    color: #6c757d !important;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    color: #6c757d !important;
  }
 
  .form-check-label{
    margin-left:7px !important;
  }
  // .marginBottomSpaceFor8px{
  //   margin-bottom: 8px;
  // }
  label{
    margin-bottom: 8px;
  }

  .badge-info{
    background-color: $badge-info !important;
  }

