$color: grey;
$content_color: black;
$bg-color:#f3f6fb !default;
$bg-sec-color:#e9ecef !default;
$white: #fff !default;
$bg-red:red;
$bg-green:green;
$bg-master-button: #eba900 !default;
$bg-action-button:#001e61 !default;
$bg-model-body:#f4f8fb;
$bg-font-colour : #ffff00;
$bg-email-link-colour : #3385ff;
$disabled: #b4b4b4 !default;
$bg-primary-color:#4dbfd9;
$nav-border-color:#e9ecef;
$badge-info:#17a2b8;

// Change below variable if the height of the navbar changes
$navbar-base-height: 40px;
// Change below variable to change the width of the sidenav
$sidebar-base-width: 220px;
// Change below variable to change the width of the sidenav when collapsed
$sidebar-collapsed-width: 65px;