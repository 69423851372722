@import "_variables.scss";
@import "_mixins.scss";
@import "_common.scss";

@font-face {
  font-family: OpenSansBold;
  src: url(fonts/Open_Sans/OpenSans-Bold.ttf);
}

@font-face {
  font-family: OpenSans;
  src: url(fonts/Open_Sans/OpenSans-Regular.ttf);
}

@font-face {
  font-family: CSI;
  src: url(fonts/csi/mmsarica_csi.ttf);
}

@font-face {
  font-family: Lora;
  src: url(fonts/lora/Lora-Regular.ttf);
}



