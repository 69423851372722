@import "@ng-select/ng-select/themes/default.theme.css";
body {
  margin: 0;
  padding: 0;
  font-size: 0.78rem !important;
  line-height: 1.5 !important;
  font-family: OpenSans !important;
  font-weight: 500;
  letter-spacing: 0.2px;
  overflow-x: hidden;
  height: 100%;
  color: black !important;
}
body ng-dropdown-panel .ng-dropdown-panel-items .total-padding {
  min-height: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: OpenSans;
  letter-spacing: 0.2px;
  margin-bottom: 0.5rem;
}

h6 {
  font-weight: bold;
}

.mb-3 .form-control {
  font-size: 0.78rem;
}
.mb-3 .form-control:read-only {
  background: #f9f9f9;
  border-color: #ced4da;
  box-shadow: none;
}
.mb-3 .form-control:disabled {
  background: #f9f9f9;
  border-color: #ced4da;
  box-shadow: none;
}
.mb-3 .input-group .input-group-append .btn {
  height: 2rem;
  background: #fff;
  line-height: 1px;
}
.mb-3 .input-group .input-group-append .btn:hover {
  background: #fff;
  color: #001e61;
}
.mb-3 .input-group .input-group-append .btn:disabled {
  color: #989fa5;
}

.form-control {
  font-size: 0.78rem !important;
}
.form-control:read-only {
  background: #f9f9f9;
  border-color: #ced4da;
  box-shadow: none;
}
.form-control:disabled {
  background: #f9f9f9;
  border-color: #ced4da;
  box-shadow: none;
}

.dragDrop {
  border: 1px solid #ccc;
  height: 6rem;
  padding: 5px;
  border-radius: 5px;
  background: white;
  width: 100%;
  white-space: normal;
  overflow: auto;
}

.dragLabel {
  color: gray;
}

.input-group .input-group-append .btn {
  height: 2rem;
  line-height: 1px;
}
.input-group .input-group-append .btn:hover {
  background: #fff;
  color: #001e61;
}
.input-group .input-group-append .btn:disabled {
  color: #989fa5;
}

.ng-select .ng-select-container {
  min-height: 33px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 33px;
}

ui-switch .switch:focus {
  outline: none;
}

.bold-title {
  font-weight: bold;
}

.ngx-datatable.material {
  box-shadow: none !important;
  border-radius: 0px 0px 10px 10px !important;
  border: 1px solid hsl(210, 14%, 83%) !important;
}
.ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  background: #001e61 !important;
  color: #fff !important;
  padding: 0.5rem 1rem !important;
}
.ngx-datatable.material .datatable-header .datatable-header-cell .sort-btn {
  color: white;
  font-size: 20px;
}
.ngx-datatable.material .datatable-body .datatable-row-detail {
  overflow-x: hidden;
}
.ngx-datatable.material .datatable-body .datatable-body-cell-label {
  font-size: 0.78rem;
}

.month-picker dp-date-picker {
  width: 100%;
  border: 1px solid #ced4da;
  height: 100%;
  border-radius: 4px;
}
.month-picker dp-date-picker input {
  font-size: 15px !important;
  height: 2.1rem !important;
  width: 21.5rem !important;
  border: none !important;
}
.month-picker dp-date-picker:last-child {
  box-sizing: border-box;
  font-size: 13px;
  height: 2.1rem;
  width: 100%;
  border: none;
}
.month-picker input:focus {
  border-color: #80bdff;
  transition: all 0.5s;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.ng-autocomplete {
  width: 15rem;
}
.ng-autocomplete ::ng-deep .autocomplete-container {
  height: 2rem;
}
.ng-autocomplete ::ng-deep .autocomplete-container input {
  border-radius: 0.25rem;
  height: 2rem;
}

.modal-dialog {
  margin-bottom: 60px !important;
}

.modal-header {
  padding: 0.25rem;
  background: #001e61;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.modal-header .modal-title {
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  color: #fff;
  margin-bottom: 0;
  line-height: 1rem;
  vertical-align: middle;
}
.modal-header .close {
  background-color: transparent;
  color: white;
  border: none;
  font-size: x-large;
  font-weight: bold;
}
.modal-header .btn-close:hover,
.modal-header .btn-close:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.accordian-bg {
  background-color: #e9ecef !important;
  border: none !important;
}

.breadcrumb-item.active {
  color: #6c757d !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #6c757d !important;
}

.form-check-label {
  margin-left: 7px !important;
}

label {
  margin-bottom: 8px;
}

.badge-info {
  background-color: #17a2b8 !important;
}

@font-face {
  font-family: OpenSansBold;
  src: url(fonts/Open_Sans/OpenSans-Bold.ttf);
}
@font-face {
  font-family: OpenSans;
  src: url(fonts/Open_Sans/OpenSans-Regular.ttf);
}
@font-face {
  font-family: CSI;
  src: url(fonts/csi/mmsarica_csi.ttf);
}
@font-face {
  font-family: Lora;
  src: url(fonts/lora/Lora-Regular.ttf);
}