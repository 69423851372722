/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "node_modules/ngx-spinner/animations/ball-clip-rotate-pulse.css";
@import 'ngx-toastr/toastr';
body {
  min-width: 83rem;
  overflow-x: auto;

  .auto-grow-1_5x {
    display: flex;

    .ng-dropdown-panel {
      min-width: 150% !important;
      width: auto;
    }

    .ng-dropdown-panel-items {
      min-width: 150% !important;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      background: white;
    }
  }


  .auto-grow-2x {
     display: flex;

    .ng-dropdown-panel {
      min-width: 200% !important;
      width: auto;
    }

    .ng-dropdown-panel-items {
      min-width: 200% !important;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      background: white;
    }
  }

  .auto-grow-location-2x {
     display: flex;

    .ng-dropdown-panel {
      min-width: 170% !important;
      width: auto;
    }

    .ng-dropdown-panel-items {
      min-width: 170% !important;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      background: white;
    }
  }

  .auto-grow-2x-uniformfileds {
     display: flex;

    .ng-dropdown-panel {
      min-width: 300% !important;
      width: auto;
    }

    .ng-dropdown-panel-items {
      min-width: 300% !important;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      background: white;
    }
  }
  a {
    text-decoration: none;
  }

  a:hover {
        text-decoration: underline; /* Optional: Add underline on hover if desired */
    }


  
}
